if (jQuery('.tronc-compare-wrapper').length) {

  let dataregion = jQuery('.tronc-compare').attr('data-region'),
  regionformat = dataregion == 'IE' ? 'en-IE' : 'en-UK',
  regioncurrency = dataregion == 'IE' ? 'EUR' : 'GBP';

  const formatter = new Intl.NumberFormat(regionformat, {
    style: 'currency',
    currency: regioncurrency,
    maximumFractionDigits: 2,
  });

  let rangeLinkCalc = document.getElementById('numberRange'),
  savingEst13 = jQuery('.savingEst13'),
  savingEst11 = jQuery('.savingEst11'),
  savingEst12 =  jQuery('.savingEst12'),
  savingEst20 =  jQuery('.savingEst20'),
  savingEst4 =  jQuery('.savingEst4'),
  savingEstSub1220 =  jQuery('.savingEstSub1220'),
  savingEstSub20 =  jQuery('.savingEstSub20'),
  savingEstAdd1220 = jQuery('.savingEstAdd1220'),
  savingEstAdd2 = jQuery('.savingEstAdd2'),
  savingEstSub2 = jQuery('.savingEstSub2'),
  savingEstAdd420 = jQuery('.savingEstAdd420'),
  savingEstSub420 = jQuery('.savingEstSub420'),
  
calcSavingsEmp = (val) => {
  document.getElementById('selectedValue').innerText = val;
  savingEst13.text(formatter.format(val * .138));
  savingEst11.text(formatter.format(val * .1105));
  savingEst4.text(formatter.format(val * .04));
  savingEst12.text(formatter.format(val * .12));
  savingEst20.text(formatter.format(val * .20));
  savingEst20.text(formatter.format(val * .20));
  savingEstAdd1220.text(formatter.format((val * .12) + (val * .20)));
  savingEstAdd2.text(formatter.format(2 + (val * .20) + (val * .04)));
  savingEstSub2.text(formatter.format(val - (2 + (val * .20) + (val * .04))));
  savingEstAdd420.text(formatter.format((val * .04) + (val * .20)));
  savingEstSub1220.text(formatter.format(val - (val * .12) - (val * .20)));
  savingEstSub20.text(formatter.format(val - ((val * .04)  + val * .20)));
  savingEstSub420.text(formatter.format(val - ((val * .04) + (val * .20))));
};

calcSavingsEmp(rangeLinkCalc.value)
 rangeLinkCalc.addEventListener('input', function() {
    calcSavingsEmp(this.value)
  });

  jQuery('#numberRange').on('input', () => {
    jQuery('.range-bg').css('background', `linear-gradient(90deg, rgba(11,173,127,1) 0%, rgba(11,173,127,1) ${rangeLinkCalc.value / 1000}%, rgba(255,255,255,0) ${rangeLinkCalc.value / 1000}%, rgba(250,250,250,1) ${rangeLinkCalc.value / 1000}%`);
    jQuery('.selectedValueWrapper').css('left', `calc(${rangeLinkCalc.value / 1000}% - ${rangeLinkCalc.value / 1000}px)`);
});

  jQuery('.selectedValueWrapper').on('click', ()=> {
    jQuery('.hidden-user-input').removeClass('hidden');
    jQuery('.hidden-user-input').show();
    jQuery('.selectedValueWrapper').addClass('userInput');
  });

  jQuery('.selectedValueWrapper').on('keyup', function(event) {
    if (event.key === 'Enter') {
      calcSavingsEmp(jQuery('.hidden-user-input').val());
      rangeLinkCalc.value = jQuery('.hidden-user-input').val();
      jQuery('.range-bg').css('background', `linear-gradient(90deg, rgba(11,173,127,1) 0%, rgba(11,173,127,1) ${rangeLinkCalc.value / 1000}%, rgba(255,255,255,0) ${rangeLinkCalc.value / 1000}%, rgba(250,250,250,1) ${rangeLinkCalc.value / 1000}%`);
      jQuery('.selectedValueWrapper').css('left', `calc(${rangeLinkCalc.value / 1000}% - ${rangeLinkCalc.value / 1000}px)`);
      jQuery('.hidden-user-input').hide();
      jQuery('.selectedValueWrapper').removeClass('userInput');
    }
  });
  
}

/*********************************
/* Accordions
/********************************/
jQuery('.accordion-trigger').on('click', function(e) {
  
  let accordion_container = jQuery(this).closest('.accordion-container');
  let accordion = jQuery(this).closest('.accordion');

  // Always one accordion opened at a time, and minimum one opened
  if (!accordion.hasClass('open') && !accordion_container.hasClass('self-close')) {
    accordion_container.find('.accordion.open').removeClass('open');
    accordion_container.find('.accordion-content').stop().slideUp(300);
    accordion.find('.accordion-content').stop().slideToggle(300);
    accordion.toggleClass('open');
  }
  // More than one accordion can be opened at a time (used in FAQs and feature comparison table)
  else if (accordion_container.hasClass('self-close')) {
    accordion.find('.accordion-content').stop().slideToggle(300);
    accordion.toggleClass('open');
  }
});

